export default [
  {
    key: 'Albums',
    label: '精選專輯',
    route: {
      name: 'explore-type',
      params: {
        type: 'albums',
      },
    },
  },
  {
    key: 'Playlists',
    label: '精選歌單',
    route: {
      name: 'explore-type',
      params: {
        type: 'playlists',
      },
    },
  },
  {
    key: 'Genres',
    label: '精選曲風',
    route: {
      name: 'explore-type',
      params: {
        type: 'genres',
      },
    },
  },
  {
    key: 'Moods',
    label: '精選氣氛',
    route: {
      name: 'explore-type',
      params: {
        type: 'moods',
      },
    },
  },
];
