<template>
  <div
    class="ly-menu"
    :class="[
      `ly-menu--${themeSetting[themeType].bgColor}`,
      {
        'ly-menu--fixed': isFixedHeaderVisible,
      },
    ]"
  >
    <div class="ly-menu__content" :class="{ 'ly-menu__content--fixed': themeType === 'fixed' }">
      <div class="ly-menu__content__menu">
        <div class="menu-logo" v-show="isFixedHeaderVisible">
          <router-link to="/">
            <img class="menu-logo__img menu-logo__img--tablet" src="@/assets/home/logo.svg" />
            <img class="menu-logo__img menu-logo__img--mobile" src="@/assets/mobile/logo.svg" />
          </router-link>
        </div>

        <ul class="menu-list">
          <li
            class="menu-list__item"
            :class="`menu-list__item--${themeSetting[themeType].labelColor}`"
            v-for="item of menuList"
            :key="item.key"
            @click="handleClick(item.key, item.routeName)"
          >
            <span class="menu-list__item__label">
              {{ item.label }}
            </span>
            <div class="menu-list__item__hover-label-box">
              <span class="menu-list__item__hover-label-box__hover-label">
                {{ item.hoverLabel }}
              </span>
            </div>
          </li>
        </ul>
      </div>
      <div class="ly-menu__content__funtion" :class="{ 'ly-menu__content__funtion--fixed': themeType === 'fixed' }">
        <div class="show-mobile">
          <mobile-header-function-list />
        </div>
        <div class="show-tablet">
          <header-function-list icon-color="black" />
          <!-- <menu-function-list :icon-color="headerStyle === 'black' ? 'white' : 'black'" /> -->
        </div>
      </div>
    </div>

    <menu-search v-show="isFixedHeaderVisible" />

    <div
      class="menu-expand-content"
      :class="{ 'menu-expand-content--fixed-header': isFixedHeaderVisible }"
      @mouseleave="closeInnerContent"
    >
      <Transition name="slide-fade">
        <menu-explore v-show="menuStatus.explore" @closeInnerContent="closeInnerContent" />
      </Transition>
    </div>
  </div>
</template>
<script>
import {
  defineComponent, ref, reactive, computed,
} from 'vue';
import { debounce } from 'lodash-es';
import { useRouter } from 'vue-router';
import menuList from '@/config/menuList';
import HeaderFunctionList from '@/layout/HeaderFunctionList.vue';
import MenuExplore from '@/views/Home/MenuExplore.vue';
import MenuSearch from '@/layout/MenuSearch.vue';
import { useSiteStore } from '@/store/site';
import MobileHeaderFunctionList from '@/layout/MobileHeaderFunctionList.vue';

export default defineComponent({
  name: 'Menu',
  components: {
    MobileHeaderFunctionList,
    HeaderFunctionList,
    MenuSearch,
    MenuExplore,
  },
  setup() {
    const siteStore = useSiteStore();
    const router = useRouter();
    const isFixedHeaderVisible = ref(false);
    const themeType = ref('static');
    const menuStatus = reactive({
      explore: false,
      licensing: false,
      contract: false,
    });
    const menuExpandKey = ref('');
    const appRoot = computed(() => siteStore.appRoot);
    const oldOffsetTop = ref(0);

    const themeSetting = {
      static: {
        labelColor: 'white',
        bgColor: 'black',
      },
      fixed: {
        labelColor: 'black',
        bgColor: 'white',
      },
    };

    const handleClick = (key, routeName) => {
      if (key === 'explore') {
        menuExpandKey.value = key;
        menuStatus[menuExpandKey.value] = !menuStatus[menuExpandKey.value];
        return;
      }

      router.push({
        name: routeName,
      });
    };

    const closeInnerContent = () => {
      console.log('1');
      menuStatus[menuExpandKey.value] = false;
    };

    const showFixedHeader = debounce(() => {
      const yOffset = appRoot.value.scrollTop;

      // tablet(含) 以上，超過頂部 80px 出現
      if (siteStore.deviceType.includes('tablet')) {
        if (yOffset > 80) {
          isFixedHeaderVisible.value = true;
          themeType.value = 'fixed';
        } else {
          isFixedHeaderVisible.value = false;
          themeType.value = 'static';
        }
        // tablet 以下，向上滾動時才出現，向下滾動時不出現
      } else if (oldOffsetTop.value > yOffset) {
        isFixedHeaderVisible.value = true;
        themeType.value = 'fixed';
      } else {
        isFixedHeaderVisible.value = false;
        themeType.value = 'static';
      }

      oldOffsetTop.value = yOffset;
    }, 50);

    appRoot.value.addEventListener('scroll', showFixedHeader);

    return {
      menuList,
      menuStatus,
      handleClick,
      isFixedHeaderVisible,
      themeSetting,
      themeType,
      closeInnerContent,
    };
  },
});
</script>
<style lang="scss" scoped>
@import '~@/styles/share/animate.scss';
.ly-menu {
  display: block;
  position: relative;

  &--fixed {
    width: 100%;
    height: 80px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: $zi-fixed-header;
    box-sizing: border-box;
    border-bottom: $c-assist5 1px solid;
    @include flex(center);
  }

  &--white {
    background-color: $c-white;
  }

  &--black {
    background-color: $c-black;
  }

  &__content {
    @include max-width(1700);
    @include flex(center);

    &--fixed {
      @include flex(space-between);
    }

    &__menu {
      @include flex();
    }

    &__funtion {
      display: none;

      &--fixed {
        display: inline-block;
      }
    }
  }
}

.menu-search-box {
  @include position(tr, calc(100% + 1px), 0);
  width: 50px;
  height: 50px;
  border-bottom-left-radius: 10px;
  background-color: $c-main;
  cursor: pointer;

  &__icon {
    @include position(center);
  }
}

.menu-expand-content {
  width: 100%;
  @include position(tl, calc(100% + 1px), 0);

  max-height: calc(90vh - 160px);
  overflow-y: auto;
  box-shadow: 0 3px 3px rgba($c-black, 0.1);

  &--fixed-header {
    max-height: calc(90vh - 53px);
  }
}

.menu-logo {
  margin-right: 56px;

  &__img {
    &--tablet {
      display: none;
    }
  }
}

.menu-list {
  display: none;

  &__item {
    width: 95px;
    height: 24px;
    cursor: pointer;
    display: inline-block;
    @include inline-flex(center);

    &--white {
      color: $c-white;
    }

    &--black {
      color: $c-black;
    }

    & + & {
      margin-left: 64px;
    }

    &__label {
      @include font-size(18);
    }

    &__hover-label-box {
      display: none;
    }
  }
}

.menu-list__item {
  &:hover {
    .menu-list__item__label {
      display: none;
    }

    .menu-list__item__hover-label-box {
      display: inline-block;

      &__hover-label {
        @include font-size(18);
        position: relative;
        display: inline-block;
        z-index: 1;

        &::after {
          content: '';
          background-image: url('~@/assets/icon/highlight_3.png');
          @include position(br, -5px, -10px);
          width: 60px;
          height: 10px;
          display: inline-block;
          z-index: -1;
        }
      }
    }
  }
}

.show-tablet {
  display: none;
}

@media screen and (min-width: $tablet) {
  .show-mobile {
    display: none;
  }

  .show-tablet {
    display: block;
  }
  .ly-menu {
    display: block;
    position: relative;

    &--fixed {
      width: 100%;
      height: 80px;
      position: fixed;
      top: 0;
      left: 0;
      z-index: $zi-fixed-header;
      box-sizing: border-box;
      border-bottom: $c-assist5 1px solid;
      @include flex(center);
    }

    &--white {
      background-color: $c-white;
    }

    &--black {
      background-color: $c-black;
    }

    &__content {
      @include max-width(1700);
      @include flex(center);

      &--fixed {
        @include flex(space-between);
      }

      &__menu {
        @include flex();
      }

      &__funtion {
        display: none;

        &--fixed {
          display: inline-block;
        }
      }
    }
  }

  .menu-search-box {
    @include position(tr, calc(100% + 1px), 0);
    width: 50px;
    height: 50px;
    border-bottom-left-radius: 10px;
    background-color: $c-main;
    cursor: pointer;

    &__icon {
      @include position(center);
    }
  }

  .menu-expand-content {
    width: 100%;
    @include position(tl, calc(100% + 1px), 0);
    box-shadow: 0 3px 3px rgba($c-black, 0.1);
    max-height: calc(90vh - 53px);
    overflow-y: auto;

    &--fixed-header {
      max-height: calc(90vh - 53px);
    }
  }

  .menu-logo {
    margin-right: 56px;

    &__img {
      &--mobile {
        display: none;
      }

      &--tablet {
        display: inline-block;
      }
    }
  }

  .menu-list {
    display: block;
    @include padding(14px 0);

    &__item {
      width: 95px;
      height: 24px;
      cursor: pointer;
      display: inline-block;
      @include inline-flex(center);

      &--white {
        color: $c-white;
      }

      &--black {
        color: $c-black;
      }

      & + & {
        margin-left: 64px;
      }

      &__label {
        @include font-size(18);
      }

      &__hover-label-box {
        display: none;
      }
    }
  }

  .menu-list__item {
    &:hover {
      .menu-list__item__label {
        display: none;
      }

      .menu-list__item__hover-label-box {
        display: inline-block;

        &__hover-label {
          @include font-size(18);
          position: relative;
          display: inline-block;
          z-index: 1;

          &::after {
            content: '';
            background-image: url('~@/assets/icon/highlight_3.png');
            @include position(br, -5px, -10px);
            width: 60px;
            height: 10px;
            display: inline-block;
            z-index: -1;
          }
        }
      }
    }
  }
}
</style>
