<template>
  <div class="ly-header">
    <div class="header-content">
      <!-- FIXME 換 logo -->
      <div class="header-content__left">
        <router-link to="/">
          <img class="header-content__logo__img" src="@/assets/mobile/logo_white.svg" />
        </router-link>
      </div>
      <div class="header-content__logo">
        <router-link to="/">
          <img class="header-content__logo__img" src="@/assets/home/logo_w.svg" />
        </router-link>
      </div>
      <div class="header-content__right">
        <div class="show-mobile">
          <mobile-header-function-list type="home" />
        </div>
        <div class="show-tablet">
          <header-function-list icon-color="white" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent } from 'vue';
import HeaderFunctionList from '@/layout/HeaderFunctionList.vue';
import MobileHeaderFunctionList from '@/layout/MobileHeaderFunctionList.vue';

export default defineComponent({
  name: 'Header',
  components: {
    HeaderFunctionList,
    MobileHeaderFunctionList,
  },
});
</script>
<style lang="scss" scoped>
.ly-header {
  height: 60px;
  border-bottom: $c-assist13 1px solid;
  @include flex(center);
  position: relative;
}

.header-content {
  @include flex(space-between);
  @include max-width(1700);
  height: 100%;
  position: relative;

  &__left {
    width: auto;
  }

  &__logo {
    display: none;
    // @include position(center);
  }

  &__right {
    width: auto;
  }
}

.show-tablet {
  display: none;
}

@media screen and (min-width: $tablet) {
  .show-mobile {
    display: none;
  }

  .show-tablet {
    display: block;
  }

  .ly-header {
    background-color: $c-black;
    height: 102px;
    border-bottom: $c-assist13 1px solid;
    @include flex(center);
    position: relative;
  }

  .header-content {
    @include flex(flex-end);
    @include max-width(1700);
    height: 100%;
    background-color: $c-black;
    position: relative;

    &__left {
      width: auto;
      display: none;
    }

    &__logo {
      display: inline-block;
      @include position(center);
    }

    &__right {
      width: auto;
    }
  }
}
</style>
