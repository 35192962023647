<template>
  <div class="f-album-brick">
    <div class="f-album-brick-content">
      <ul class="brick-wall">
        <li class="brick-wall__item" v-for="i of imageList" :key="i" @click="toAlbumsInfo(i)">
          <img class="brick-wall__item__img" :src="`./static/brick/img_${i}.png`" />
          <div class="brick-wall__item__mask"></div>
          <div class="brick-wall__item__desc-box">
            <p class="brick-wall__item__desc-box__album">ALBUM</p>
            <p class="brick-wall__item__desc-box__name">Beyond The Clouds</p>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import { defineComponent, computed, onMounted } from 'vue';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { useRouter } from 'vue-router';
import { useSiteStore } from '@/store/site';

gsap.registerPlugin(ScrollTrigger);

export default defineComponent({
  name: 'AlbumBrick',
  setup() {
    const router = useRouter();
    const siteStore = useSiteStore();

    const mockImageList = ['10', '11', '12', '13', '14', '15', '16', '17', '18', '19'];
    const imageList = computed(() => {
      if (siteStore.deviceType.includes('tablet')) {
        return mockImageList;
      }

      return mockImageList.filter((item, i) => i < 6);
    });

    const toAlbumsInfo = (info) => {
      router.push({
        name: 'explore-albums-info',
        query: {
          id: info,
          title: `albumsTitle${info}`,
        },
      });
    };

    onMounted(() => {
      gsap.to('.brick-wall', {
        scrollTrigger: '.brick-wall',
        y: 0,
        duration: 4,
      });
    });

    return {
      imageList,
      toAlbumsInfo,
    };
  },
});
</script>
<style lang="scss" scoped>
.brick-wall {
  display: flex;
  flex-wrap: wrap;

  &__item {
    flex: calc(100% / 2);
    position: relative;
    cursor: pointer;

    &:hover {
      .brick-wall__item__mask {
        width: 100%;
        height: 100%;
        background: transparent linear-gradient(180deg, #12c5ff 0%, #8431ff 50%, #f8559e 100%) 0% 0% no-repeat
          padding-box;
        opacity: 0.3;
      }

      .brick-wall__item__desc-box {
        opacity: 1;
      }
    }

    &__img {
      width: 100%;
      vertical-align: bottom;
    }

    &__mask {
      @include position(tl, 0, 0);
      transition: 0.5s;
      opacity: 0;
    }

    &__desc-box {
      transition: 0.5s;
      opacity: 0;
      @include position(bl, 16px, 20px);

      &__album {
        @include font-style($c-white, 14, bold, 0.7px, 30px);
      }

      &__name {
        @include font-style($c-white, 18, bold, 0.9px, 30px);
      }
    }
  }
}

@media screen and (min-width: $portrait) {
  .brick-wall {

    &__item {
      flex: calc(100% / 3);

    }
  }
}

@media screen and (min-width: $tablet) {
  .brick-wall {

    &__item {
      flex: calc(100% / 5);

    }
  }
}
</style>
