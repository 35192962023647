<template>
  <div class="f-hot">
    <div class="f-hot__content">
      <div class="title-tabs">
        <div class="title-tabs__hot-title">
          <red-decorate-title title="熱門精選" />
        </div>
        <div class="title-tabs__tabs">
          <tabs type="hotTab"/>
        </div>
      </div>

      <div class="mobile-hot-list show-mobile">
        <mobile-hot-list :data="mobileHotListConfig" />
      </div>

      <div class="show-tablet">
        <div class="music-list">
          <div class="music-list__left" ref="musicListLeft">
            <ul class="hot-album">
              <li class="hot-album__item" v-for="i of mockLeftImageList" :key="i" @click="toPage(i)">
                <img class="hot-album__item__img" :src="`./static/hot/img_${i}.png`" />
                <p class="hot-album__item__text">SCIENCE & TECHNOLOGY - RESEARCH</p>
                <div class="hot-album__item__mask"></div>
              </li>
            </ul>
          </div>
          <div class="music-list__right" ref="musicListRight">
            <ul class="hot-album">
              <li class="hot-album__item" v-for="i of mockRightImageList" :key="i" @click="toPage(i)">
                <img class="hot-album__item__img" :src="`./static/hot/img_${i}.png`" />
                <p class="hot-album__item__text">SCIENCE & TECHNOLOGY - RESEARCH</p>
                <div class="hot-album__item__mask"></div>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="learn-more-wrap show-tablet">
        <red-circle-button
          label="了解更多"
          :routeInfo="{
            name: 'explore-type',
            params: {
              type: 'playlists',
            },
          }"
        />
      </div>
    </div>
  </div>
</template>
<script>
import {
  defineComponent, onMounted, ref, computed,
} from 'vue';
import { useRouter } from 'vue-router';
import RedDecorateTitle from '@/components/Global/RedDecorateTitle.vue';
import RedCircleButton from '@/components/Home/RedCircleButton.vue';
import Tabs from '@/components/Global/Tabs.vue';
import MobileHotList from '@/components/Home/MobileHotList.vue';
import mobileHotListConfig from '@/config/mobileHotListConfig';
import { useSiteStore } from '@/store/site';

export default defineComponent({
  name: 'Hot',
  components: {
    RedDecorateTitle,
    RedCircleButton,
    Tabs,
    MobileHotList,
  },
  setup() {
    const router = useRouter();
    const siteStore = useSiteStore();
    const appRoot = computed(() => siteStore.appRoot);

    const mockLeftImageList = ['1', '2', '3', '4'];
    const mockRightImageList = ['5', '6', '7', '8'];

    const musicListLeft = ref(null);
    const musicListRight = ref(null);

    const handleScroll = () => {
      const windowTopOffsetForLeft = musicListLeft.value.getBoundingClientRect().top;
      const windowTopOffsetForRgiht = musicListRight.value.getBoundingClientRect().top;
      const threshold = document.body.clientHeight * 0.9;

      if (threshold > windowTopOffsetForLeft) {
        musicListLeft.value.classList.add('animate__animated', 'animate__fadeInLeftBig');
      }

      if (threshold > windowTopOffsetForRgiht) {
        musicListRight.value.classList.add('animate__animated', 'animate__fadeInRightBig');
        appRoot.value.removeEventListener('scroll', handleScroll);
      }
    };

    const toPage = (i) => {
      router.push({
        name: 'explore-albums-info',
        query: {
          id: i,
          title: `albumsTitle${i}`,
        },
      });
    };

    onMounted(() => {
      appRoot.value.addEventListener('scroll', handleScroll);
    });

    return {
      mockLeftImageList,
      mockRightImageList,
      musicListLeft,
      musicListRight,
      toPage,
      mobileHotListConfig,

    };
  },
});
</script>
<style lang="scss" scoped>
.f-hot {
  margin-top: 56px;
  margin-bottom: 80px;
}

.title-tabs {
  display: none;
}

.mobile-hot-list {
  @include padding(0 0 0 20px);
}

.show-tablet {
  display: none;
}

@media screen and (min-width: $tablet) {
  .show-mobile {
    display: none;
  }

  .show-tablet {
    display: block;
  }

  .f-hot {
    margin-top: 108px;
  }

  .title-tabs {
    @include padding(0 0 0 220px);
    @include max-width(1700, false);
    @include flex(space-between);
  }

  .learn-more-wrap {
  margin-top: 95px;
  @include flex(center, center);
}

  .music-list {
    margin-top: 57px;

    &__left {
      opacity: 0;
      // @include padding(0 11.770833% 0 0);
    }

    &__right {
      opacity: 0;
      @include flex(flex-end);
      margin-top: 70px;
      // @include padding(0 0 0 11.770833%);
    }
  }

  .hot-album {
    display: flex;
    flex-wrap: wrap;
    @include max-width(1700, false);

    &__item {
      flex: calc(100% / 6);
      position: relative;
    cursor: pointer;

      &__mask {
        @include position(tl, 0, 0);
        width: 100%;
        height: 100%;
        transition: 0.4s;
        opacity: 0;

        &:hover {
          background: transparent linear-gradient(180deg, #12c5ff 0%, #8431ff 50%, #f8559e 100%) 0% 0% no-repeat
            padding-box;
          opacity: 0.3;
        }
      }

      & + & {
        margin-left: 5px;
      }

      &__img {
        width: 100%;
        vertical-align: bottom;
      }

      &__text {
        @include font-style($c-white, 18, normal, 0, 24px);
        @include position(bl, 14px, 17px);
      }
    }
  }
}
</style>
