<template>
  <ul class="ly-header-function-list funcition-list">
    <li class="funcition-list__item funcition-list__item--search" v-if="iconColor === 'white'">
      <img src="@/assets/icon/header_search.svg" @click="openSearchPop" />
    </li>

    <li class="funcition-list__item funcition-list__item--user">
      <template v-if="iconColor === 'white'" >
        <img src="@/assets/icon/header_user.svg" @click="toMmeberPage"/>
      </template>
      <template v-else>
        <img src="@/assets/icon/header_user_black.svg"  @click="toMmeberPage"/>
      </template>

      <span
        class="funcition-list__item__sign-in"
        :class="`funcition-list__item__sign-in--${iconColor}`"
        @click="handleClick"
        >{{ isLogin ? 'SIGN OUT' : 'SIGN IN' }}</span
      >
    </li>

    <li class="funcition-list__item funcition-list__item--language">
      <language :iconColor="iconColor" />
    </li>
  </ul>
</template>
<script>
import { defineComponent, computed } from 'vue';
import { useRouter } from 'vue-router';
import { useSiteStore } from '@/store/site';
import Language from '@/components/Layout/Language/index.vue';

export default defineComponent({
  name: 'HeaderFunctionList',
  components: {
    Language,
  },
  props: {
    iconColor: {
      type: String,
      default: 'white',
    },
  },
  setup() {
    const siteStore = useSiteStore();
    const router = useRouter();

    const isLogin = computed(() => siteStore.isLogin);

    const openSearchPop = () => {
      siteStore.toggleShowSearchPop(true);
    };

    const handleClick = () => {
      if (isLogin.value) {
        siteStore.handleLogout();
      } else {
        router.push({ name: 'sign-in' });
      }
    };

    const toMmeberPage = () => {
      if (isLogin.value) {
        router.push({ name: 'my-profile-personal-details' });
      } else {
        router.push({ name: 'sign-in' });
      }
    };

    return {
      openSearchPop,
      handleClick,
      isLogin,
      toMmeberPage,
    };
  },
});
</script>
<style lang="scss" scoped>
.funcition-list {
  @include flex();

  &__item {
    cursor: pointer;

    &--search {
    }

    &--user {
      margin-left: 23px;
      @include flex();
    }

    &--language {
      margin-left: 43px;
      @include flex();
    }

    &__sign-in {
      margin-left: 12px;

      &--white {
        @include font-style($c-white, 16, 400, 0.8px);
      }

      &--black {
        @include font-style($c-black, 16, 400, 0.8px);
      }
    }
  }
}
</style>
