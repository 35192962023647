<template>
  <div class="c-tabs">
    <ul class="tab-list">
      <li
        class="tab-list__item"
        v-for="tab of hotTabsConfig"
        :key="tab.icon"
        @mouseenter="hoverEffect('hotActive', tab.key)"
        @mouseleave="hoverEffect('hot', tab.key)"
        @click="handleClick(tab.route)"
      >
        <component
          :is="`${tab.key}Icon`"
          mainType="hot"
          :colorType="colorType"
          :currIcon="currIcon"
          :iconKey="tab.key"
        />
        <span class="tab-list__item__text">{{ tab.label }}</span>
      </li>
    </ul>
  </div>
</template>
<script>
import { defineComponent, ref } from 'vue';
import { useRouter } from 'vue-router';
import hotTabsConfig from '@/config/hotTabsConfig';
import ThemesIcon from '@/components/Explore/Icon/ThemesIcon.vue';
import MoodsIcon from '@/components/Explore/Icon/MoodsIcon.vue';
import GenresIcon from '@/components/Explore/Icon/GenresIcon.vue';
import AlbumsIcon from '@/components/Explore/Icon/AlbumsIcon.vue';
import PlaylistsIcon from '@/components/Explore/Icon/PlaylistsIcon.vue';
import Playlists from '../Explore/Playlists.vue';

export default defineComponent({
  name: 'Tabs',
  components: {
    // IconComp,
    ThemesIcon,
    MoodsIcon,
    GenresIcon,
    AlbumsIcon,
    PlaylistsIcon,
    Playlists,
  },
  props: {
    type: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const router = useRouter();
    const colorType = ref('hot');
    const currIcon = ref('');
    const hoverEffect = (type, icon) => {
      console.log('type, icon', type, icon);
      colorType.value = type;
      currIcon.value = icon;
    };

    const handleClick = (route) => {
      if (props.type === 'hotTab') {
        console.log('搜尋該類別');

        return;
      }
      router.push(route);
    };

    return {
      hotTabsConfig,
      colorType,
      currIcon,
      hoverEffect,
      handleClick,

    };
  },
});
</script>
<style lang="scss" scoped>
.c-tabs {
  display: inline-block;
}

.tab-list {
  &__item {
    @include inline-flex();
    cursor: pointer;

    &:hover {
      .tab-list__item__text {
        color: $c-black;
      }
    }

    &__icon {
      display: inline-block;
      width: 18px;
      height: 18px;
      background-repeat: no-repeat;
      background-size: 18px 18px;
    }

    &__text {
      @include font-style($c-text2, 20, 400, 1px);
      margin-left: 10px;
    }

    & + & {
      margin-left: 33px;
    }
  }
}
</style>
