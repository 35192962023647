<template>
  <div class="f-slogan" ref="fSlogan">
    <div class="f-slogan__content g-content">
      <div class="en-title">
        <p class="en-title__text">WE ARE NOW THE</p>
        <p class="en-title__text">
          <span class="en-title__text__bright" ref="brightLargest">LARGEST</span> COPYRIGHTED
        </p>
        <p class="en-title__text">
          <span class="en-title__text__bright" ref="brightMusic">MUSIC</span> AGENT
          <span class="en-title__text__bright" ref="inTaiwan">IN TAIWAN</span>.
        </p>
      </div>

      <div class="ch-text-btn">
        <div class="ch-text-btn__ch-text">
          <p class="ch-text">音韶為台灣最大版權音樂代理商，超過140萬首音樂提供業界多樣化選擇，</p>
          <p class="ch-text">齊全、豐富的音樂，可滿足各類需求。</p>
        </div>
        <div class="ch-text-btn__btn-wrap">
          <red-circle-button
            label="了解更多"
            label-color="#ffffff"
            label-bg="#000000"
            :routeInfo="{
              name: 'front-about-us',
            }"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  defineComponent, onMounted, ref, computed,
} from 'vue';
import { useSiteStore } from '@/store/site';

import RedCircleButton from '@/components/Home/RedCircleButton.vue';

export default defineComponent({
  name: 'Slogan',
  components: {
    RedCircleButton,
  },
  setup() {
    const siteStore = useSiteStore();
    const appRoot = computed(() => siteStore.appRoot);
    const fSlogan = ref(null);
    const brightLargest = ref(null);
    const brightMusic = ref(null);
    const inTaiwan = ref(null);

    const handleScroll = () => {
      const windowTopOffset = fSlogan.value.getBoundingClientRect().top;
      const threshold = document.body.clientHeight;

      if (threshold > windowTopOffset) {
        brightLargest.value.classList.add('animate__animated', 'animate__brightenWhite');
        brightMusic.value.classList.add('animate__animated', 'animate__brightenWhite', 'delay--bright-music');
        inTaiwan.value.classList.add('animate__animated', 'animate__brightenWhite', 'delay--in-taiwan');
        appRoot.value.removeEventListener('scroll', handleScroll);
      }
    };

    onMounted(() => {
      appRoot.value.addEventListener('scroll', handleScroll);
    });

    return {
      fSlogan,
      brightLargest,
      brightMusic,
      inTaiwan,
    };
  },
});
</script>
<style lang="scss" scoped>
.f-slogan {
  background-color: #000;

  &__content {
    box-sizing: border-box;
    padding-top: 87px;
    padding-bottom: 56px;
  }
}

.en-title {
  &__text {
    // FIXME 設計稿被播放器擋住了，要確定樣式
    @include font-style($c-text3, 20, 800, 3px, 28px);

    &__bright {
      color: $c-white;
    }
  }
}

.ch-text-btn {
  @include flex(flex-start, flex-start, column);
  margin-top: 30px;

  &__btn-wrap {
    margin-top: 40px;
  }
}

.ch-text {
  @include font-style($c-white, 16, 400, 0.9px, 30px);
}

@keyframes brightenWhite {
  from {
    color: $c-text3;
    transform: translate3d(100%, 0, 0);
  }

  to {
    color: $c-white;
    transform: translate3d(0, 0, 0);
  }
}
.animate__brightenWhite {
  -webkit-animation-name: brightenWhite;
  animation-name: brightenWhite;
}

$delayBase: 0.3;
.delay {
  &--bright-music {
    animation-delay: $delayBase + s;
  }

  &--in-taiwan {
    animation-delay: calc($delayBase * 2) + s;
  }
}

@media screen and (min-width: $tablet) {

  .f-slogan {
  background-color: #000;

  &__content {
    @include max-width(1480);
    padding-bottom: 126px;
  }
}
  .en-title {
    &__text {
      @include font-style($c-text3, 60, 800, 3px, 60px);
    }
  }

  .ch-text-btn {
    @include flex(space-between);

    &__btn-wrap {
      margin-top: 0px;
    }
  }

  .ch-text {
    @include font-style($c-white, 16, 400, 0.9px, 30px);
  }
}
</style>
