<template>
  <div class="c-mobile-hot-list" v-for="d of data" :key="d.key">
    <div class="title-more">
      <div class="title-more__title">
        <component :is="`${d.key}Icon`" mainType="explore" colorType="hotActive" :currIcon="d.key" :iconKey="d.key" />
        <span class="title-more__title__text">{{ d.label }}</span>
      </div>
      <div class="title-more__more" @click="toPage(d.route)">了解更多</div>
    </div>

    <div class="album-wrap">
      <ul class="album-list">
        <li class="album-list__item" v-for="i of 6" :key="i" @click="toPage(i)">
          <img class="album-list__item__img" src="@/assets/album/6.jpeg" />
          <p class="album-list__item__name">ALBUM NAME</p>
          <p class="album-list__item__desc">GM213</p>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import { defineComponent, ref } from 'vue';
import { useRouter } from 'vue-router';
import ThemesIcon from '@/components/Explore/Icon/ThemesIcon.vue';
import MoodsIcon from '@/components/Explore/Icon/MoodsIcon.vue';
import GenresIcon from '@/components/Explore/Icon/GenresIcon.vue';
import AlbumsIcon from '@/components/Explore/Icon/AlbumsIcon.vue';
import PlaylistsIcon from '@/components/Explore/Icon/PlaylistsIcon.vue';
// import ffd from '@/config/mobileHotListConfig'

export default defineComponent({
  name: 'MobileHotList',
  components: {
    ThemesIcon,
    MoodsIcon,
    GenresIcon,
    AlbumsIcon,
    PlaylistsIcon,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
  setup() {
    const router = useRouter();

    const colorType = ref('hot');
    const currIcon = ref('');

    const hoverEffect = (type, icon) => {
      colorType.value = type;
      currIcon.value = icon;
    };

    const toPage = (route) => {
      router.push(route);
    };

    return {
      // hotTabsConfig,
      colorType,
      currIcon,
      hoverEffect,
      toPage,
    };
  },
});
</script>
<style lang="scss" scoped>
.c-mobile-hot-list {
  & + & {
    margin-top: 40px;
  }
}

.title-more {
  width: 100%;
  @include flex(space-between);
  @include padding(0 20px 0 0);

  &__title {
    @include flex();
    @include font-style($c-black, 24, 700, 1.2px, 33px);

    &__text {
      margin-left: 12px;
    }
  }

  &__more {
    @include font-style($c-assist4, 14, 400, 1.2px, 20px);
    cursor: pointer;
  }
}

.album-wrap {
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;

  &::-webkit-scrollbar {
    display: none;
  }
}
.album-list {
  @include flex(flex-start, center);
  margin-top: 15px;

  &__item {
    width: 140px;

    &:last-child {
      padding-right: 20px;
    }

    & + & {
      margin-left: 16px;
    }

    &__img {
      width: 100%;
    }

    &__name {
      @include font-style($c-black, 14, 400, 0px, 16px);
      margin-top: 8px;
    }

    &__desc {
      @include font-style($c-assist4, 14, 400, 0px, 16px);
      margin-top: 2px;
    }
  }
}
</style>
