<template>
  <div class="f-explore">
    <div class="explore-title">
      <red-decorate-title title="探索屬於 您的靈感" />
    </div>

    <div class="mesh-background ">
      <div class="explore-title-carousel">
        <div class="explore-title-carousel__content g-content">
          <div class="explore-carousel-wrap">
            <div class="explore-carousel-images">
              <img class="explore-carousel-images__img-expand" src="@/assets/home/img_9.png" />

              <!-- <img
              v-for="item of manipulateImageList"
              :class="{ 'explore-carousel-images__img--active': item.show }"
              :key="item.url"
              class="explore-carousel-images__img"
              :src="item.url"
            /> -->
            </div>
            <div class="explore-carousel-index">
              <p class="explore-carousel-index__current-number">{{ currImagesIndex }}</p>
              <div class="explore-carousel-index__bar">
                <div class="explore-carousel-index__bar__phase-basic"></div>
                <div
                  class="explore-carousel-index__bar__phase"
                  :class="[`explore-carousel-index__bar__phase--move${currIndex + 1}`]"
                ></div>
              </div>
              <p class="explore-carousel-index__all-number">{{ totalImagesCount }}</p>
            </div>
            <div class="explore-carousel-timer">
              <div class="explore-carousel-timer__next">
                <div class="circle">
                  <svg class="circle__svg circle__static">
                    <g>
                      <circle cx="47" cy="47" r="45" />
                      <!-- cx="47" cy="47" r="45" -->
                    </g>
                  </svg>
                  <svg class="circle__svg circle__animate">
                    <g>
                      <circle cx="47" cy="47" r="45" />
                      <!-- cx="47" cy="47" r="45" -->
                    </g>
                  </svg>
                </div>
                <div class="next">
                  <img class="next__icon" src="@/assets/icon/icon_arrow_right.svg" />
                </div>
              </div>
            </div>
          </div>

          <div class="explore-slogan">
            <div class="explore-slogan__title">
              <p class="explore-slogan__title__text">LIQUID CINEMA ＆</p>
              <p class="explore-slogan__title__text">INSIDE TRACKS</p>
            </div>
            <div class="explore-slogan__desc">
              無論是在電影預告片、促銷、廣告、電視、體育節目、真人秀和電子遊戲中，Liquid Cinema 和 Inside Tracks
              的配樂均享負盛名，能成為世界上最受歡迎的兩個音樂庫當然還包含很多原因。透過以下介紹了解更多。
            </div>
          </div>
        </div>

        <div class="explore-more-btn-wrap">
          <red-circle-button
            label="了解更多"
            label-color="#ffffff"
            label-bg="#000000"
            :routeInfo="{
              name: 'explore-type',
              params: {
                type: 'themes',
              },
            }"
          />
        </div>
      </div>

      <div class="about-web g-content">
        <div class="about-web__title">
          <red-decorate-title :title="$t('global.aboutPoint')" title-color="#ffffff" />
        </div>
        <p class="about-web__text">
          音韶是全世界最大音樂版權公司 Universal Publishing Production Music 台灣地區獨家代理權，並代理 Warner Chappell
          Music 及全世界知名廠牌版權音樂，堪稱台灣最大版權音樂代理商，提供業界最專業及優質的服務。
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent, ref, computed } from 'vue';
import RedDecorateTitle from '@/components/Global/RedDecorateTitle.vue';
import RedCircleButton from '@/components/Home/RedCircleButton.vue';

export default defineComponent({
  name: 'Explore',
  components: {
    RedCircleButton,
    RedDecorateTitle,
  },
  setup() {
    const timer = ref(null);
    const currIndex = ref(0);
    const transformSize = ref(0);
    const manipulateImageList = ref([
      {
        url: './static/testing/img_9_1.png',
        show: true,
      },
      {
        url: './static/testing/img_9_2.png',
        show: false,
      },
      {
        url: './static/testing/img_9_3.png',
        show: false,
      },
      {
        url: './static/testing/img_9_4.png',
        show: false,
      },
    ]);

    const adjustImageList = () => {
      manipulateImageList.value = manipulateImageList.value.map((item, index) => {
        if (index === currIndex.value) {
          return {
            url: item.url,
            show: true,
          };
        }

        return {
          url: item.url,
          show: false,
        };
      });
    };

    const startCarousel = () => {
      timer.value = setInterval(() => {
        if (currIndex.value === manipulateImageList.value.length - 1) {
          currIndex.value = 0;
        } else {
          currIndex.value += 1;
          transformSize.value = currIndex.value;
        }

        adjustImageList();
      }, 3000);
    };

    const currImagesIndex = computed(() => {
      if (currIndex.value < 10) {
        return `0${currIndex.value + 1}`;
      }
      return currIndex.value + 1;
    });

    const totalImagesCount = computed(() => {
      if (manipulateImageList.value.length < 10) {
        return `0${manipulateImageList.value.length}`;
      }
      return manipulateImageList.value.length;
    });

    startCarousel();

    return {
      timer,
      currIndex,
      manipulateImageList,
      startCarousel,
      transformSize,
      currImagesIndex,
      totalImagesCount,
    };
  },
});
</script>
<style lang="scss" scoped>
$moveOffset: 126px;

.f-explore {
  margin-top: 80px;
}

.explore-title {
  @include flex(center, center);
}

.mesh-background {
  @include padding(0px 110px 55px);
  margin-top: 24px;
  background-color: $c-black;
  @include padding(0px 20px 37px);
}

.explore-title-carousel {
  padding-top: 40px;
  position: relative;

  &__content {
    @include flex(flex-start, flex-start, column);
    position: relative;
    top: 0px;
  }
}

.explore-slogan {
  flex: none;
  width: 100%;
  max-width: 480px;
  position: relative;
  margin-top: 30px;

  &__title {
    width: 100%;
    position: relative;

    &__text {
      @include font-style($c-white, 28, 700, 1.4px, 34px);
    }
  }

  &__desc {
    margin-top: 32px;
    @include font-style($c-white, 16, 400, 0.8px, 30px);
  }
}

.explore-carousel-wrap {
  flex: 1;
  // margin-left: 60px;
  position: relative;
  width: 100%;
}

.explore-carousel-images {
  position: relative;

  &__img {
    @include position(tl, 0, 0);
    width: 100%;
    vertical-align: top;
    opacity: 0;
    transition: 0.6s 0.6s linear;

    &--active {
      opacity: 1;
      transition: 0.6s linear;
    }
  }

  &__img-expand {
    width: 100%;
    vertical-align: top;
  }
}

.explore-carousel-index {
  @include position(bl, 27px, 30px);
  @include flex();
  width: 50%;

  &__current-number {
    @include font-style($c-main, 14, 400, 0);
  }

  &__bar {
    width: 100%;
    height: 1px;
    background-color: $c-main-light;
    margin: 0 17px;

    &__phase {
      width: 25%;
      height: 1px;
      background-color: $c-main;
      transition: 1s;
      transform-origin: left;

      &--move1 {
        animation: move1 1s forwards;
      }

      &--move2 {
        animation: move2 1s forwards;
      }

      &--move3 {
        animation: move3 1s forwards;
      }

      &--move4 {
        animation: move4 1s forwards;
      }
    }
  }

  &__all-number {
    @include font-style($c-main, 14, 400, 0);
  }
}

.explore-carousel-timer {
  position: relative;

  &__next {
    position: absolute;
    top: 50%;
    right: 0%;
    transform: translateY(-50%);
    cursor: pointer;
  }
}

.circle {
  width: 100%;
  stroke-width: 1px;
  fill: none;
  width: 94px;
  height: 94px;
  transform: rotate(-90deg) scale(0.5);
  transition: 1s linear;

  &:hover {
    transform: rotate(90deg) scale(0.5);

    .circle__animate {
      stroke-dashoffset: 0;
    }
  }

  &__svg {
    @include position(center);
    height: 94px;
    width: 94px;
  }

  &__static {
    width: 94px;
    stroke: $c-main-light;
    stroke-width: 1px;
    fill: none;
  }

  &__animate {
    stroke: $c-main;
    stroke-width: 3px;
    stroke-dashoffset: 314;
    stroke-dasharray: 314;
    transition: 1s linear;
  }
}

.next {
  width: 8px;
  @include position(center);
  z-index: -1;

  &__icon {
    width: 100%;
  }
}

.explore-more-btn-wrap {
  margin-top: 32px;
  @include flex(center, center);
}

.about-web {
  width: 100%;
  // max-width: 993px;
  margin: 100px auto 0;

  &__title {
    @include flex(center, center);
  }

  &__text {
    @include font-style($c-white, 18, 400, 0.8px, 30px);
    margin-top: 36px;
    text-align: center;
  }
}

@keyframes move1 {
  from {
    transform: scaleX(0);
  }

  to {
    transform: scaleX(1);
  }
}

@keyframes move2 {
  from {
    transform: scaleX(1);
  }

  to {
    transform: scaleX(2);
  }
}

@keyframes move3 {
  from {
    transform: scaleX(2);
  }

  to {
    transform: scaleX(3);
  }
}

@keyframes move4 {
  from {
    transform: scaleX(3);
  }

  to {
    transform: scaleX(4);
  }
}

@media screen and (min-width: $portrait) {
  .circle {
    transform: rotate(-90deg) scale(0.75);

    &:hover {
      transform: rotate(90deg) scale(0.75);
    }
  }

  .next {
    width: 10px;
  }

  .explore-slogan {
    margin: 0 auto;
    margin-top: 30px;
    // max-width: 600px;
    text-align: center;
  }
}

@media screen and (min-width: $tablet) {
  .f-explore {
    margin-top: 150px;
  }

  .mesh-background {
    @include padding(0px 0px 55px);
    background-image: linear-gradient(rgba(34, 34, 34, 1) 1px, transparent 0),
      linear-gradient(90deg, rgba(34, 34, 34, 1) 1px, transparent 0);
    background-size: 20% 25%;
  }

  .explore-title-carousel {
    padding-top: 30px;

    &__content {
      @include flex(flex-start);
      top: 0px;
    }
  }

  .explore-slogan {
    text-align: left;
    margin-top: 0px;
    width: 40%;
    order: 1;

    &__title {
      &__text {
        @include font-style($c-white, 32, 700, 2.25px);
      }
    }

    &__desc {
      margin-top: 32px;
      @include font-style($c-white, 18, 400, 0.9px);
    }
  }

  .explore-carousel-wrap {
    margin-left: 60px;
    order: 2;
  }

  .explore-carousel-images {
    position: relative;

    &__img {
      @include position(tl, 0, 0);
    }
  }

  .explore-more-btn-wrap {
    margin-top: 7.4%; // 換算設計稿 126px
    @include flex(center, center);
  }

  .explore-carousel-timer {
    &__next {
      right: 5%;
    }
  }

  .circle {
    transform: rotate(-90deg) scale(0.75);

    &:hover {
      transform: rotate(90deg) scale(0.75);
    }
  }

  .next {
    width: 15px;
  }
}

@media screen and (min-width: $sm-pc) {
  .explore-title {
    margin-bottom: 150px;
  }

  .explore-title-carousel {
    &__content {
      top: -126px;
    }
  }

  .explore-carousel-index {
    width: 30%;
  }
  .explore-slogan {
    width: 33%;
    margin-top: 100px;
  }

  .explore-carousel-timer {
    &__next {
      right: 90px;
    }
  }

  .circle {
    transform: rotate(-90deg) scale(1);

    &:hover {
      transform: rotate(90deg) scale(1);
    }
  }

  .next {
    width: auto;
  }
}

@media screen and (min-width: $pc) {

  .explore-slogan {

    &__title {
      &__text {
        @include font-size(45)
      }
    }
  }

}

</style>
