<template>
  <div class="f-inherit">
    <div class="f-inherit__content g-content">
      <div class="title-btn">
        <div class="title-btn__title">
          <div class="title-btn__title__title-wrap">
            <RedDecorateTitle title="讓音樂延續下去" />
            <p class="title-btn__title__title-wrap__text">創建您的免費帳戶，開始探索音樂，創造感動體驗</p>
          </div>
          <div class="title-btn__title__btn">
            <red-circle-button label="SIGN UP" :routeInfo="{ name: 'sign-up' }" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent } from 'vue';

import RedDecorateTitle from '@/components/Global/RedDecorateTitle.vue';
import RedCircleButton from '@/components/Home/RedCircleButton.vue';

export default defineComponent({
  name: 'Inherit',
  components: {
    RedDecorateTitle,
    RedCircleButton,
  },
  setup() {},
});
</script>
<style lang="scss" scoped>
.f-inherit {
  @include padding(56px 0 100px);
  background-image: url('~@/assets/licensing/img_deco.png');
  background-size: 280px auto;
  background-repeat: no-repeat;
  background-position: bottom right;
  @include flex(flex-start, flex-start, column);

  &__content {

  }
}

.title-btn {
  &__title {
    @include flex(flex-start, flex-start, column);

    &__title-wrap {
      &__text {
        margin-top: 15px;
        @include font-style($c-black, 18, 400);
      }
    }

    &__btn {
      margin-top: 40px;
    }
  }
}

@media screen and (min-width: $portrait) {
  .f-inherit {

    background-size: 400px auto;
  }
}

@media screen and (min-width: $sm-pc) {
  .f-inherit {
    background-position: center right;
    height: 400px;
    background-size: 863px;
    background-image: url('~@/assets/home/bg_deco.png');
    @include flex(space-between);

  }

  .title-btn {
    &__title {
      @include flex(space-between);

      &__btn {
        margin-top: 0px;
      }
    }
  }
}
</style>
