<template>
  <div class="f-first-view">
    <div class="f-first-view__content">
      <header class="header-wrap">
        <header-comp />
        <menu-comp />
      </header>
      <div class="animate-search-wrap">
        <div class="canvas-animate">
          <iframe class="canvas-animate__iframe" src="./canvas/animate_old.html"></iframe>
        </div>

        <div class="logo-input-text-wrap g-content">
          <div class="logo-input-text">
            <div class="logo-input-text__logo">
              <img class="logo-input-text__logo__img" src="@/assets/home/logo_kv.svg" />
              <!-- <img class="logo-input-text__logo__img-mobile" src="@/assets/home/logo_kv.svg" /> -->
            </div>

            <div class="logo-input-text__input" @click="openSearchPop">
              <div class="input-wrap">
                <input class="input-wrap__field" placeholder="搜索關鍵字、樂器、流派、心情..." />
              </div>
              <div class="search">
                <img class="search__icon" src="@/assets/icon/kv_search.svg" />
              </div>
            </div>

            <p class="logo-input-text__text">MUSIC TO BACK UP YOUR STORY.</p>
            <div class="mobile-arrow">
              <img src="@/assets/mobile/kv_arrow.svg" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent } from 'vue';
// +Comp 避免和原生名稱衝突
import { useSiteStore } from '@/store/site';
import HeaderComp from '@/views/Home/Header.vue';
import MenuComp from '@/views/Home/Menu.vue';

export default defineComponent({
  name: 'FirstView',
  components: {
    HeaderComp,
    MenuComp,
  },
  setup() {
    const siteStore = useSiteStore();

    const openSearchPop = () => {
      siteStore.toggleShowSearchPop(true);
    };

    return {
      openSearchPop,
    };
  },
});
</script>
<style lang="scss" scoped>
.f-first-view {
  width: 100%;
  height: 100%;
  background-color: $c-black;

  &__content {
    width: 100%;
    height: 100%;
    position: relative;
    @include flex(flex-start, flex-start, column);
  }
}

.header-wrap {
  width: 100%;
  flex: none;
  height: auto;
  position: relative;
  z-index: $zi-header;
}

.animate-search-wrap {
  width: 100%;
  height: 100%;
  position: relative;
}

.logo-input-text-wrap {
  width: 100%;
  height: 100%;
  position: relative;
  @include flex(center, center);
}

.logo-input-text {
  @include flex(center, center, column);
  // @include position(center);
  flex: 1;

  &__logo {
    text-align: center;

    &__img {
      width: 160px;
      vertical-align: bottom;
    }
  }

  &__input {
    @include flex();
    @include padding(10px 6px 10px 20px);
    margin-top: 42px;
    width: 100%;
    height: 44px;
    border: 1px solid var(--unnamed-color-ffffff);
    background-color: $c-home-search-bg;
    box-shadow: 0px 3px 15px #000000b3;
    border: 1px solid #ffffff;
    border-radius: 45px;
  }

  &__text {
    width: 250px;
    margin-top: 42px;
    text-align: center;
    @include font-style($c-white, 26, 800);
  }
}

.input-wrap {
  flex: 1;

  &__field {
    width: 100%;
    border: 0px transparent;
    outline: 0px transparent;
    background-color: transparent;
    height: 28px;
    line-height: 28px;
    @include font-style($c-input-placeholder, 16, 800, 1px);

    &::placeholder {
      @include font-style($c-input-placeholder, 16, 800, 1px);
    }
  }
}

.search {
  width: 57px;

  &__icon {
    width: 100%;
    vertical-align: bottom;
  }
}

.mobile-arrow {
  margin-top: 13px;
}

.canvas-animate {
  @include position(tl, 0, 0);
  /* z-index: -1; */
  width: 100%;
  height: 100%;

  &__iframe {
    width: 100%;
    height: 100%;
  }
}

@media screen and (min-width: $tablet) {
  .logo-input-text {
    &__logo {
      &__img {
        width: auto;
      }
    }

    &__input {
      height: 77px;
      @include padding(12px 18px 12px 37px);
      max-width: 900px;
    }

    &__text {
      width: auto;

      @include font-style($c-white, 35, 800, 0px, 77px);
    }
  }

  .input-wrap {
    flex: 1;

    &__field {
      @include font-style($c-input-placeholder, 20, 800, 1px);

      &::placeholder {
        @include font-style($c-input-placeholder, 20, 800, 1px);
      }
    }
  }

  .search {
    cursor: pointer;
    width: 95px;
  }

  .mobile-arrow {
    display: none;
  }
}
</style>
