<template>
  <div class="v-home">
    <first-view :menuStatus="menuStatus" />
    <slogan />
    <hot />
    <explore />
    <album-brick />
    <inherit />
    <site-map />
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import FirstView from '@/views/Home/FirstView.vue';
import Slogan from '@/views/Home/Slogan.vue';
import Hot from '@/views/Home/Hot.vue';
import Explore from '@/views/Home/Explore.vue';
import AlbumBrick from '@/views/Home/AlbumBrick.vue';
import Inherit from '@/views/Home/Inherit.vue';
import SiteMap from '@/views/Home/SiteMap.vue';

export default defineComponent({
  name: 'Home',
  props: [
    'menuStatus',
  ],
  components: {
    FirstView,
    Slogan,
    Hot,
    Explore,
    AlbumBrick,
    Inherit,
    SiteMap,
  },
});
</script>

<style>
.v-home {
  width: 100%;
  height: 100%;
}
</style>
